







































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { firestore, storage } from "../firebaseInit";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, getStorage, uploadBytes } from "firebase/storage";
import { Art } from "../models/Art";
import { VarietyPack } from "@/models/VarietyPack";

@Component({ components: {} })
export default class ManageVarietyPacks extends Vue {
  private readonly storage = getStorage();
  private readonly FileUploadStatus = Object.freeze({
    INITIAL: "INITIAL",
    SAVING: "SAVING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
  });

  private varietyPackArray: VarietyPack[] = [];
  private reproductionArray: Art[] = [];
  private selectedPackId: string = "";
  private selectedImageUrl: string = "";
  private showSuccessModal: boolean = true;
  private fileUploadStatus = this.FileUploadStatus.INITIAL;
  private fileToUpload: File | null = null;
  private newVarietyPack = {
      id: '',
      name: '',
      description: '',
      price: 0,
      count: 5,
      storageLocation: '',
      isActive: true,
      options: []
    }

  private addSelectionId = '';

  get selectedVarietyPack(): VarietyPack {
    const existingVarietyPack = this.varietyPackArray.find((art) => {
      return art.id === this.selectedPackId;
    });

    return existingVarietyPack || this.newVarietyPack;
  }

  get fileSize() {
    if (!this.fileToUpload) return 0;

    const sizeInMB = this.fileToUpload.size / (1024 * 1024);
    if (sizeInMB > 1) return sizeInMB.toFixed(2) + " MB";

    const sizeInKB = this.fileToUpload.size / 1024;

    return sizeInKB.toFixed(0) + " KB";
  }

  get fileSizeInKB() {
    if (!this.fileToUpload) return 0;
    else return (this.fileToUpload.size / 1024).toFixed(0);
  }

  async created() {
    await this.getVarietyPacksFromDb();
    await this.getReproductionsFromDb();
    if (this.varietyPackArray.length > 0) this.selectedPackId = this.varietyPackArray[0].id;
  }

  async getVarietyPacksFromDb() {
    const packArray: VarietyPack[] = [];
    const imagesRef = collection(firestore, "variety-packs");
    const collectionSnap = await getDocs(imagesRef);

    collectionSnap.forEach((doc) => {
      let pack = { ...doc.data(), id: doc.id };
      packArray.push(pack as VarietyPack);
    });

    this.varietyPackArray = packArray;

    if (this.varietyPackArray.length < 1) this.selectedPackId = 'new';
  }

  async getReproductionsFromDb() {
    const artArray: Art[] = [];
    const imagesRef = collection(firestore, "reproductions");
    const collectionSnap = await getDocs(imagesRef);

    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id };
      artArray.push(art as Art);
    });

    this.reproductionArray = artArray;
  }

  get reproductionsToAdd() {
    this.addSelectionId // reference to ensure the getter updates
    const alreadySelectedOptions = this.selectedVarietyPack.options.map(option => option.id);
    return this.reproductionArray.filter(art => !alreadySelectedOptions.includes(art.id))
  }

  async deletePack() {
    const confirmed = confirm("Are you sure you want to delete this?");
    if (confirmed) {
      const documentToDelete = doc(
        firestore,
        "variety-packs",
        this.selectedPackId
      );
      await deleteDoc(documentToDelete).catch(() => {
        alert("Error deleting variety pack, please try again.");
        return;
      });

      alert("Successfully deleted variety pack!");
      await this.getVarietyPacksFromDb();
      if (this.varietyPackArray.length > 0) this.selectedPackId = this.varietyPackArray[0].id;
      else this.selectedPackId = 'new'
    }
  }

  handleSubmit() {
    if (this.selectedPackId === 'new') this.createVarietyPack()
    else this.updateVarietyPack();
  }

  addSelectionOption() {
    const reproduction = this.reproductionArray.find(art => art.id === this.addSelectionId);
    if (!reproduction) return;

    this.selectedVarietyPack.options.push(reproduction);
    this.addSelectionId = '';
  }

  removeSelectionOption(removeOption: Art) {
    this.selectedVarietyPack.options = this.selectedVarietyPack.options.filter(option => option.id !== removeOption.id)
  }

  async createVarietyPack() {
    if (
      !(
        this.selectedVarietyPack.name &&
        this.selectedVarietyPack.description &&
        this.selectedVarietyPack.price &&
        this.fileToUpload
      )
    ) {
      alert("Please fill out all fields before submitting.");
      return;
    }

    if (
      !(
        this.selectedVarietyPack.count > 0 &&
        this.selectedVarietyPack.options.length > 1
      )
    ) {
      alert("Please enter a valid selection count and options.");
      return;
    }

    const varietyPackCollection = collection(firestore, "variety-packs");
    const updatePromises: Promise<any>[] = [];
    const document: any = {
      ...this.selectedVarietyPack,
      createdDate: new Date().toLocaleString(),
      storageLocation: `${this.selectedVarietyPack.name}-${new Date().toISOString()}`,
    };

    updatePromises.push(addDoc(varietyPackCollection, document));
    this.fileUploadStatus = this.FileUploadStatus.SAVING;
    const storageRef = ref(storage, document.storageLocation);
    updatePromises.push(uploadBytes(storageRef, this.fileToUpload));

    await Promise.all(updatePromises).catch(() => {
      alert("Error uploading variety pack, please try again.");
      return;
    });
    alert("Successfully uploaded variety pack!");
    await this.getVarietyPacksFromDb();

    this.fileUploadStatus = this.FileUploadStatus.INITIAL;
    this.fileToUpload = null;

    this.newVarietyPack = {
      id: '',
      name: '',
      description: '',
      price: 0,
      count: 5,
      storageLocation: '',
      isActive: true,
      options: []
    }
  }

  async updateVarietyPack() {
    const documentToUpdate = doc(firestore, "variety-packs", this.selectedPackId);
    const updatePromises: Promise<any>[] = [];

    if (
      !(
        this.selectedVarietyPack.name &&
        this.selectedVarietyPack.description &&
        this.selectedVarietyPack.price
      )
    ) {
      alert("Please fill out all fields before submitting.");
      return;
    }

    if (
      !(
        this.selectedVarietyPack.count > 0 &&
        this.selectedVarietyPack.options.length > 1
      )
    ) {
      alert("Please enter a valid selection count and options.");
      return;
    }

    updatePromises.push(setDoc(documentToUpdate, this.selectedVarietyPack));
    if (this.fileToUpload) {
      this.fileUploadStatus = this.FileUploadStatus.SAVING;
      const storageRef = ref(storage, this.selectedVarietyPack?.storageLocation);
      updatePromises.push(uploadBytes(storageRef, this.fileToUpload));
    }

    await Promise.all(updatePromises).catch(() => {
      alert("Error updating, please try again.");
      return;
    });
    alert("Successfully updated!");
    await this.getVarietyPacksFromDb();

    this.fileUploadStatus = this.FileUploadStatus.INITIAL;
    this.fileToUpload = null;
  }

  filesChange(fileList: any[]) {
    if (!fileList.length) return;
    else this.fileToUpload = fileList[0];
  }

  @Watch(`selectedVarietyPack`)
  async getSelectedImageUrl() {
    if (!this.selectedVarietyPack.storageLocation) return "";

    const path = ref(storage, this.selectedVarietyPack.storageLocation);
    const url = await getDownloadURL(path);
    this.selectedImageUrl = url;
  }
}
