


















































































































































































import { Component, Vue } from "vue-property-decorator";
import UploadArt from "../UploadArt.vue";
import EditArt from "../EditArt.vue";
import EditReproductions from "../EditReproductions.vue";
import ManageVarietyPacks from "../ManageVarietyPacks.vue";
import SoldArt from "../SoldArt.vue";
import PageContent from "../PageContent.vue";
import { functions } from "../../firebaseInit";
import { httpsCallable } from "firebase/functions";

@Component({ components: { UploadArt, EditArt, EditReproductions, ManageVarietyPacks, SoldArt, PageContent } })
export default class Admin extends Vue {
  private authenticated = false;
  private showInvalid = false;
  private encrypted = "";
  private password = "";
  private state: "edit-gallery" | "edit-reproductions" | "upload" | "sold" | "content" | "variety-pack" = "upload";
  private authenticate = httpsCallable(functions, "authenticate");

  async handleSubmitPassword() {
    const authenticationResponse = await this.authenticate({
      password: this.password,
    });
    const authenticated = !!authenticationResponse.data;

    if (authenticated) this.authenticated = true;
    else this.showInvalid = true;
  }
}
