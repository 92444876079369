












import { Component, Vue, Watch } from "vue-property-decorator";
import ArtShowcase from "../ArtShowcase.vue";
import { firestore } from "../../firebaseInit";
import { collection, getDocs } from "firebase/firestore";
import { Art } from "../../models/Art";
import { VarietyPack } from "@/models/VarietyPack";

@Component({
  components: {
    ArtShowcase,
  },
})
export default class Reproductions extends Vue {
  private artArray: any[] = [];
  private categoryArtArray: any[] = [];
  private varietyPackArray: VarietyPack[] = [];
  private noArtTimeoutDone = false;
  private reproductionType: 'card' | 'print' | 'variety-pack' = 'variety-pack'

  async created() {
    await this.getVarietyPacks();

    setTimeout(() => {
      this.noArtTimeoutDone = true;
    }, 500);

    const imagesRef = collection(firestore, "reproductions");
    const collectionSnap = await getDocs(imagesRef);

    let artArray: Art[] = [];
    collectionSnap.forEach((doc) => {
      let art = { ...doc.data(), id: doc.id } as Art;
      if (art.isActive !== false) artArray.push(art as Art);
    });

    this.artArray = [...this.shuffle(artArray)].slice(0, 100);
    if (this.reproductionType  === 'variety-pack') this.categoryArtArray = this.varietyPackArray;
    else this.categoryArtArray = this.artArray.filter(art => art.reproductionType == this.reproductionType);
  }

  async getVarietyPacks() {
    const varietyPacksRef = collection(firestore, "variety-packs");
    const varietyPackSnap = await getDocs(varietyPacksRef);

    let varietyPackArray: VarietyPack[] = [];
    varietyPackSnap.forEach((doc) => {
      let varietyPack = { ...doc.data(), id: doc.id } as VarietyPack;
      if (varietyPack.isActive !== false) varietyPackArray.push(varietyPack as VarietyPack);
    });

    console.log({varietyPackArray})
    this.varietyPackArray = [...this.shuffle(varietyPackArray)].slice(0, 100);
  }

  @Watch('reproductionType')
  handleReproductionTypeChange() {
    if (this.reproductionType === 'variety-pack') this.categoryArtArray = this.varietyPackArray;
    else this.categoryArtArray = this.artArray.filter(art => art.reproductionType == this.reproductionType);
  }

  shuffle(array: any[]): any[] {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
  }
}
